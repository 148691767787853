
export const AppMenus = {

  navbarTopRight: [],
  navbarTopLeft: [
    {
      "to": "/admin",
      "label": "Managers",
      "icon": "pi pi-users",
      "iconcolor": "",
      "target": "",

    }
  ],
  navbarSideLeft: [
    {
      "to": "/home",
      "label": "Home",
      "icon": "pi pi-th-large",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/drivers",
      "label": "Drivers",
      "icon": "pi pi-car",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/ridedetail",
      "label": "Rides Request",
      "icon": "pi pi-car",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/delivery",
      "label": "Delivery Request",
      "icon": "pi pi-truck",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/driversupport",
      "label": "Driver Support",
      "icon": "pi pi-user",
      "iconcolor": "",
      "target": "",

    },

    {
      "to": "/usersupport",
      "label": "User Support",
      "icon": "pi pi-user",
      "iconcolor": "",
      "target": "",

    },


    {
      "to": "/driverfeedback",
      "label": "Driver Feedback",
      "icon": "pi pi-check-square",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/userfeedback",
      "label": "User Feedback",
      "icon": "pi pi-check-square",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/notifications",
      "label": "Notifications",
      "icon": "pi pi-info-circle",
      "iconcolor": "",
      "target": "",

    },

  ],
  driverStatus: [
    { value: "OFFLINE", label: "Active" },
    { value: "BLOCKED", label: "Blocked" },
    { value: "SUSPENDED", label: "Suspended" },
    { value: "IN-REVIEW", label: "In-Review" },
    { value: "PENDING-REVIEW", label: "Pending-Review" },
  ],
  vehicleClass: [
    { value: "Standard", label: "Standard" },
    { value: "Executive", label: "Executive" },
    { value: "Taxi", label: "Taxi" },
    { value: "Truck", label: "Truck" },
    { value: "Motor", label: "Motor" }
  ],
  driverStatus2: [{ "label": "Free", "value": "FREE" }, { "label": "In-Transit", "value": "IN-TRANSIT" }, { "label": "Pending-Review", "value": "PENDING-REVIEW" }, { "label": "Offline", "value": "OFFLINE" }, { "label": "Blocked", "value": "BLOCKED" }],
  ticketStatus: [{ "label": "Pending", "value": "PENDING" }, { "label": "Closed", "value": "CLOSED" }, { "label": "In-Review", "value": "IN-REVIEW" }, { "label": "Re-Opened", "value": "RE-OPENED" }],
  complaintCategory: [{ "label": "Rude Customer", "value": "Rude Customer" }, { "label": "No Payment", "value": "No Payment" }],
  vehicleClass2: [{ "label": "Standard", "value": "Standard" }, { "label": "Taxi", "value": "Taxi" }, { "label": "Executive", "value": "Executive" }, { "label": "Truck", "value": "Truck" }, { "label": "Motor", "value": "Motor" }],
  rideStatus: [{ "label": "Completed", "value": "COMPLETED" }, { "label": "In-Transit", "value": "IN-TRANSIT" }, { "label": "Driver-Cancelled", "value": "DRIVER-CANCELLED" }, { "label": "User-Cancelled", "value": "USER-CANCELLED" }],

  exportFormats: {
    print: {
      label: 'Print',
      icon: 'pi pi-print',
      type: 'print',
      ext: 'print',
    },
    pdf: {
      label: 'Pdf',

      icon: 'pi pi-file-pdf',
      type: 'pdf',
      ext: 'pdf',
    },
    excel: {
      label: 'Excel',
      icon: 'pi pi-file-excel',
      type: 'excel',
      ext: 'xlsx',
    },
    csv: {
      label: 'Csv',
      icon: 'pi pi-table',
      type: 'csv',
      ext: 'csv',
    },
  },

}