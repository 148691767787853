
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


function passRouteToProps(route) {
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName,
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	//Dashboard routes


	//admin routes
	{
		path: '/admin/:fieldName?/:fieldValue?',
		name: 'adminlist',
		component: () => import('./pages/admin/list.vue'),
		props: route => passRouteToProps(route)
	},

	{
		path: '/admin/view/:id',
		name: 'adminview',
		component: () => import('./pages/admin/view.vue'),
		props: true
	},

	{
		path: '/account',
		name: 'adminaccountview',
		component: () => import('./pages/account/accountview.vue'),
		props: true
	},

	{
		path: '/admin/add',
		name: 'adminadd',
		component: () => import('./pages/admin/add.vue'),
		props: true
	},

	{
		path: '/admin/edit/:id',
		name: 'adminedit',
		component: () => import('./pages/admin/edit.vue'),
		props: true
	},


	//driverdaypayments routes

	//driverfeedback routes
	{
		path: '/driverfeedback/:fieldName?/:fieldValue?',
		name: 'driverfeedbacklist',
		component: () => import('./pages/driverfeedback/list.vue'),
		props: route => passRouteToProps(route)
	},


	//driverlocationsupdate routes

	//driverridedetail routes
	{
		path: '/driverridedetail/:fieldName?/:fieldValue?',
		name: 'driverridedetaillist',
		component: () => import('./pages/driverridedetail/list.vue'),
		props: route => passRouteToProps(route)
	},


	//driverridesummary routes

	//drivers routes
	{
		path: '/drivers/:fieldName?/:fieldValue?',
		name: 'driverslist',
		component: () => import('./pages/drivers/list.vue'),
		props: route => passRouteToProps(route)
	},

	{
		path: '/drivers/view/:id',
		name: 'driversview',
		component: () => import('./pages/drivers/view.vue'),
		props: true
	},

	{
		path: '/drivers/edit/:id',
		name: 'driversedit',
		component: () => import('./pages/drivers/edit.vue'),
		props: true
	},


	//driversupport routes
	{
		path: '/driversupport/:fieldName?/:fieldValue?',
		name: 'driversupportlist',
		component: () => import('./pages/driversupport/list.vue'),
		props: route => passRouteToProps(route)
	},
	{
		path: '/supporttickets/view/:id',
		name: 'supportticketsview',
		component: () => import('./pages/supporttickets/view.vue'),
		props: true
	},


	//feedbacks routes
	{
		path: '/feedbacks/:fieldName?/:fieldValue?',
		name: 'feedbackslist',
		component: () => import('./pages/feedbacks/list.vue'),
		props: route => passRouteToProps(route)
	},

	{
		path: '/feedbacks/view/:id',
		name: 'feedbacksview',
		component: () => import('./pages/feedbacks/view.vue'),
		props: true
	},

	{
		path: '/feedbacks/add',
		name: 'feedbacksadd',
		component: () => import('./pages/feedbacks/add.vue'),
		props: true
	},

	{
		path: '/feedbacks/edit/:id',
		name: 'feedbacksedit',
		component: () => import('./pages/feedbacks/edit.vue'),
		props: true
	},


	//geographycolumns routes

	//geometrycolumns routes

	//locations routes

	//messages routes
	{
		path: '/messages/:fieldName?/:fieldValue?',
		name: 'messageslist',
		component: () => import('./pages/messages/list.vue'),
		props: route => passRouteToProps(route)
	},

	{
		path: '/messages/view/:id',
		name: 'messagesview',
		component: () => import('./pages/messages/view.vue'),
		props: true
	},

	{
		path: '/messages/add',
		name: 'messagesadd',
		component: () => import('./pages/messages/add.vue'),
		props: true
	},

	{
		path: '/messages/edit/:id',
		name: 'messagesedit',
		component: () => import('./pages/messages/edit.vue'),
		props: true
	},


	//notifications routes
	{
		path: '/notifications/:fieldName?/:fieldValue?',
		name: 'notificationslist',
		component: () => import('./pages/notifications/list.vue'),
		props: route => passRouteToProps(route)
	},

	{
		path: '/notifications/add',
		name: 'notificationsadd',
		component: () => import('./pages/notifications/add.vue'),
		props: true
	},

	{
		path: '/notifications/edit/:id',
		name: 'notificationsedit',
		component: () => import('./pages/notifications/edit.vue'),
		props: true
	},


	//payments routes

	//paymentwallets routes

	//permissions routes
	{
		path: '/permissions/:fieldName?/:fieldValue?',
		name: 'permissionslist',
		component: () => import('./pages/permissions/list.vue'),
		props: route => passRouteToProps(route)
	},

	{
		path: '/permissions/view/:id',
		name: 'permissionsview',
		component: () => import('./pages/permissions/view.vue'),
		props: true
	},

	{
		path: '/permissions/add',
		name: 'permissionsadd',
		component: () => import('./pages/permissions/add.vue'),
		props: true
	},

	{
		path: '/permissions/edit/:id',
		name: 'permissionsedit',
		component: () => import('./pages/permissions/edit.vue'),
		props: true
	},


	//ridedelivery routes
	{
		path: '/ridedelivery/:fieldName?/:fieldValue?',
		name: 'ridedeliverylist',
		component: () => import('./pages/ridedelivery/list.vue'),
		props: route => passRouteToProps(route)
	},

	{
		path: '/ridedelivery/view/:id',
		name: 'ridedeliveryview',
		component: () => import('./pages/ridedelivery/view.vue'),
		props: true
	},

	{
		path: '/ridedelivery/add',
		name: 'ridedeliveryadd',
		component: () => import('./pages/ridedelivery/add.vue'),
		props: true
	},

	{
		path: '/ridedelivery/edit/:id',
		name: 'ridedeliveryedit',
		component: () => import('./pages/ridedelivery/edit.vue'),
		props: true
	},


	//ridedetail routes
	{
		path: '/ridedetail/:fieldName?/:fieldValue?',
		name: 'ridedetaillist',
		component: () => import('./pages/ridedetail/list.vue'),
		props: route => passRouteToProps(route)
	},
	{
		path: '/delivery/:fieldName?/:fieldValue?',
		name: 'deliverylist',
		component: () => import('./pages/ridedetail/delivery.vue'),
		props: route => passRouteToProps(route)
	},

	{
		path: '/ridedetail/view/:id',
		name: 'ridedetailview',
		component: () => import('./pages/ridedetail/view.vue'),
		props: true
	},


	//ridedriversearch routes

	//riderequest routes

	//roles routes
	{
		path: '/roles/:fieldName?/:fieldValue?',
		name: 'roleslist',
		component: () => import('./pages/roles/list.vue'),
		props: route => passRouteToProps(route)
	},

	{
		path: '/roles/view/:id',
		name: 'rolesview',
		component: () => import('./pages/roles/view.vue'),
		props: true
	},

	{
		path: '/roles/add',
		name: 'rolesadd',
		component: () => import('./pages/roles/add.vue'),
		props: true
	},

	{
		path: '/roles/edit/:id',
		name: 'rolesedit',
		component: () => import('./pages/roles/edit.vue'),
		props: true
	},


	//spatialrefsys routes

	//supporttickets routes
	{
		path: '/supporttickets/:fieldName?/:fieldValue?',
		name: 'supportticketslist',
		component: () => import('./pages/supporttickets/list.vue'),
		props: route => passRouteToProps(route)
	},

	{
		path: '/supporttickets/view/:id',
		name: 'supportticketsview',
		component: () => import('./pages/supporttickets/view.vue'),
		props: true
	},

	{
		path: '/supporttickets/add',
		name: 'supportticketsadd',
		component: () => import('./pages/supporttickets/add.vue'),
		props: true
	},

	{
		path: '/supporttickets/edit/:id',
		name: 'supportticketsedit',
		component: () => import('./pages/supporttickets/edit.vue'),
		props: true
	},


	//transactions routes
	{
		path: '/transactions/:fieldName?/:fieldValue?',
		name: 'transactionslist',
		component: () => import('./pages/transactions/list.vue'),
		props: route => passRouteToProps(route)
	},

	{
		path: '/transactions/view/:id',
		name: 'transactionsview',
		component: () => import('./pages/transactions/view.vue'),
		props: true
	},

	{
		path: '/transactions/add',
		name: 'transactionsadd',
		component: () => import('./pages/transactions/add.vue'),
		props: true
	},

	{
		path: '/transactions/edit/:id',
		name: 'transactionsedit',
		component: () => import('./pages/transactions/edit.vue'),
		props: true
	},


	//userfeedback routes
	{
		path: '/userfeedback/:fieldName?/:fieldValue?',
		name: 'userfeedbacklist',
		component: () => import('./pages/userfeedback/list.vue'),
		props: route => passRouteToProps(route)
	},


	//userlocationsupdate routes

	//userridedetail routes

	//users routes

	//usersupport routes
	{
		path: '/usersupport/:fieldName?/:fieldValue?',
		name: 'usersupportlist',
		component: () => import('./pages/usersupport/list.vue'),
		props: route => passRouteToProps(route)
	},


	//vehicles routes

	//populatedriversridesummary20244 routes



	//Password reset routes
	{
		path: '/index/forgotpassword',
		name: 'forgotpassword',
		component: () => import('./pages/index/forgotpassword.vue'),
		props: true
	},
	{
		path: '/index/resetpassword',
		name: 'resetpassword',
		component: () => import('./pages/index/resetpassword.vue'),
		props: true
	},
	{
		path: '/index/resetpassword_completed',
		name: 'resetpassword_completed',
		component: () => import('./pages/index/resetpassword_completed.vue'),
		props: true
	},



	{
		path: '/error/forbidden',
		name: 'forbidden',
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{
		path: '/error/notfound',
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)',
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {

	const auth = useAuth();


	const user = auth.user;
	if (user) {
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else {
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion) {
			if (savedPostion) return savedPostion;
			return { top: 0 }
		}
	});

	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		if (authRequired) {
			if (!user) {
				return next({ path: '/', query: { nexturl: to.fullPath } });
			}
			//authorize user
			if (!auth.canView(path)) {
				return next({ path: "/error/forbidden" });
			}
		}

		if (user && to.name == "index") {
			//already logged in, show home when try to access index page
			return next({ path: "/home" });
		}

		//navigate to redirect url if available
		if (to.name == "home" && to.query.nexturl) {
			return next({ path: to.query.nexturl });
		}

		//close dialog from previous page
		//store.closeDialogs('app/closeDialogs');

		next();
	});

	return router;
}
